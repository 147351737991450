import {
  AutocompleteInput,
  FileUpload,
  H,
  Label,
  Spinner,
  TextInput
} from "@enpowered/ui";
import { Formik } from "formik";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { AddPartnerCompanyWithDealOwner } from "../services/PaymentProposal";
import "./styles.scss";
import classNames from "classnames";
import { getRepresentatives } from "../services/Representatives";
import { getAllPartnerCompanies } from "../services/PartnerCompany";
import { sendInvitations } from "../services/Invitations";
import SnackBar from "../common/SnackBar";
import { MIXPANEL_COMPANY_GROUP_ID, getMixpanel } from "../services/mixpanel";
import { createOrgProfile, enumerateOrgProfiles } from "../services";
import { pickProps } from "../utils/objectFunctions";
import { getEnvironmentDomainUrlSuffix } from "../utils/fetch";

export const dealOwnerOptions = [
  { label: "Bridget Bray", value: "46334447" },
  { label: "Cam", value: "148278576" },
  { label: "Jake", value: "50804998" },
  { label: "James", value: "37699352" },
  { label: "Jesse Thompson", value: "59889925" },
  { label: "Karen Chen", value: "298918976" },
  { label: "Mike Kirkup", value: "55873059" },
  { label: "Olga Patronik", value: "48818297" },
  { label: "Ross Reida", value: "966524101" },
  { label: "Scott Andrews", value: "410082330" },
  { label: "Tomas", value: "8858696" }
];

export const AdminPage = () => {
  const [statusMessage, setStatusMessage] = useState("");
  const [message, setMessage] = useState({});
  const [isMessageSnackBarOpen, setIsMessageSnackBarOpen] = useState(false);

  const { mutate: handleSubmit, isLoading } = useMutation(
    data =>
      Promise.allSettled([
        AddPartnerCompanyWithDealOwner(data),
        createOrgProfile({
          name: data.partnerCompanyName,
          avatarUrl: `https://${process.env.REACT_APP_PARTNER_COMPANIES_IMAGES_BUCKET_CLOUDFRONT_URL}/${data.partnerLogoFilename}`,
          enpoweredApps: [
            {
              appId: "financing-accelerator",
              appName: "Financing Accelerator",
              appUrl: `https://financing${getEnvironmentDomainUrlSuffix()}.enpowered.com`
            }
          ]
        })
      ]).then(promises => {
        const errors = promises
          .filter(p => p.status === "rejected")
          .reduce(
            (ac, cur, idx) =>
              ac +
              (idx === 0
                ? "AddPartnerCompyError:"
                : "CreateOrgProfileError:" + cur.reason) +
              ";",
            ""
          );
        if (errors) {
          setMessage({
            text: errors,
            type: "error"
          });
          setIsMessageSnackBarOpen(true);
        }
        if (promises[0].status === "fulfilled") {
          const { partnerCompanyId, partnerCompanyName, dealOwnerId } =
            promises[0];
          const linkText = `https://${window.location.hostname}?id=${partnerCompanyId}`;
          setStatusMessage(
            <>
              Completed! Link: <a href={linkText}>{linkText}</a>
            </>
          );
          mixpanel.add_group(MIXPANEL_COMPANY_GROUP_ID, partnerCompanyId);
          mixpanel.get_group(MIXPANEL_COMPANY_GROUP_ID, partnerCompanyId).set({
            partnerCompanyName,
            dealOwnerName: dealOwnerOptions.find(
              d => d.dealOwnerId === dealOwnerId
            )?.label
          });
        }
      }),
    {
      onMutate: () => {
        setStatusMessage("Processing...");
      },
      onError: error => {
        setStatusMessage(error);
      }
    }
  );
  const {
    data: companiesList = [{ partnerCompanyId: "", partnerCompanyName: "" }]
  } = useQuery("list-of-companies", () => getAllPartnerCompanies());

  const mixpanel = getMixpanel();
  return (
    <div className={`adminPage p-4`}>
      <SnackBar
        messages={message.text ? [message.text] : []}
        type={message.type}
        duration={5000}
        isOpen={isMessageSnackBarOpen}
        setIsOpen={setIsMessageSnackBarOpen}
      />
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          partnerCompanyName: "",
          dealOwnerId: "",
          partnerCompanyId: "",
          usersToInvite: []
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          values,
          errors,
          touched,
          submitCount
        }) => {
          const hasError = fieldName => {
            const resolveField = (object, fieldName) =>
              fieldName.split(".").reduce((p, c) => p?.[c], object);
            return submitCount > 0
              ? resolveField(errors, fieldName)
              : resolveField(touched, fieldName) &&
                  resolveField(errors, fieldName);
          };
          return (
            <div className="block w-full py-2">
              <div>
                <h3>Add new partner company</h3>
                <form onSubmit={handleSubmit}>
                  <div className="py-1">
                    <div className="inline-block w-1/2 pr-1 align-top">
                      <Label className="whitespace-nowrap font-bold">
                        Partner Company Name
                      </Label>
                      <TextInput
                        name="partnerCompanyName"
                        id="partnerCompanyName"
                        placeholder="Contact Name"
                        value={values.partnerCompanyName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        valid={!hasError("partnerCompanyName")}
                      />
                      {hasError("consenterName") ? (
                        <label className="block text-sm text-en-red">
                          {errors.consenterName}
                        </label>
                      ) : null}
                    </div>
                    <div className="inline-block w-1/2 pl-1 align-top">
                      <Label className="whitespace-nowrap font-bold">
                        Associate Executive
                      </Label>
                      <AutocompleteInput
                        name="dealOwnerId"
                        id="dealOwnerId"
                        placeholder="Deal Owner Id"
                        value={dealOwnerOptions.find(
                          option => option.value === values.dealOwnerId
                        )}
                        label={o => o.label}
                        autocompleteData={dealOwnerOptions}
                        onSelected={option =>
                          setFieldValue("dealOwnerId", option.value)
                        }
                        onBlur={handleBlur}
                        valid={!hasError("dealOwnerId")}
                      />
                      {hasError("dealOwnerId") ? (
                        <label className="block text-sm text-en-red">
                          {errors.dealOwnerId}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="py-1">
                    <div className="inline-block pr-1 align-top w-full">
                      <FileUpload
                        accept="image/png,.png,image/svg,.svg,image/jpeg,.jpeg,image/jpg,.jpg"
                        className={{
                          "w-full p-4 h-32 flex flex-col items-center justify-center cursor-pointer rounded-lg border-dashed border-2 border-en-yellow-800 relative": true,
                          "form-button": false
                        }}
                        onChange={async files => {
                          setFieldValue("file", files[0]);
                          setFieldValue("partnerLogoFilename", files[0]?.name);
                        }}
                      >
                        <div className="flex items-center">
                          <H
                            level={4}
                            as="div"
                            className="cursor-pointer text-center"
                          >
                            Drag PNG file here or
                          </H>
                          <span className="rounded-md px-3 py-2 m-3 bg-black text-white text-sm font-bold">
                            Browse Files
                          </span>
                        </div>
                      </FileUpload>
                    </div>
                  </div>
                  <button
                    className="ml-1 inline-block yellow"
                    size="narrow"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? <Spinner /> : "Submit"}
                  </button>
                </form>
              </div>
            </div>
          );
        }}
      </Formik>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          partnerCompanyName: "",
          dealOwnerId: "",
          partnerCompanyId: "",
          usersToInvite: []
        }}
      >
        {({ handleBlur, setFieldValue, values }) => {
          const {
            data: { orgProfiles, usersList } = {
              orgProfiles: [],
              usersList: [
                { partnerRepId: "", partnerRepName: "", partnerRepEmail: "" }
              ]
            }
          } = useQuery(`org-profile-${values.partnerCompanyName}`, () =>
            enumerateOrgProfiles({ name: values.partnerCompanyName }).then(
              orgProfiles =>
                getRepresentatives(values.partnerCompanyId).then(usersList => ({
                  orgProfiles,
                  usersList
                }))
            )
          );
          return (
            <div className="my-4">
              <h3>Send Invitations</h3>
              <div>
                <div className={"py-2"}>
                  <AutocompleteInput
                    className="selectField"
                    name="partnerCompanyId"
                    id="partnerCompanyId"
                    placeholder="Partner Company"
                    value={
                      companiesList.find(
                        option =>
                          option.partnerCompanyId === values.partnerCompanyId
                      ) ?? companiesList.find(u => u.partnerCompanyId === "")
                    }
                    getOptionLabel={o => o.partnerCompanyName}
                    autocompleteData={companiesList}
                    onSelected={option => {
                      setFieldValue(
                        "partnerCompanyId",
                        option.partnerCompanyId
                      );
                      setFieldValue("usersToInvite", []);
                      setFieldValue(
                        "partnerCompanyName",
                        option.partnerCompanyName
                      );
                    }}
                    onBlur={handleBlur}
                  />
                </div>
                <div className={"py-2"}>
                  <AutocompleteInput
                    className={classNames({
                      "selectField inviteUsers": true
                    })}
                    name="usersToInvite"
                    id="usersToInvite"
                    placeholder="Select users"
                    multiple={true}
                    autocompleteData={[
                      {
                        partnerRepId: "#all",
                        partnerRepName: "Select all",
                        partnerRepEmail: "all"
                      }
                    ].concat(usersList)}
                    getOptionLabel={o =>
                      `${o.partnerRepName} <${o.partnerRepEmail}>`
                    }
                    value={values.usersToInvite}
                    maxResults={5}
                    onBlur={handleBlur}
                    onSelected={selected => {
                      if (selected.some(s => s.partnerRepId === "#all"))
                        setFieldValue("usersToInvite", usersList);
                      else setFieldValue("usersToInvite", selected);
                    }}
                  />
                </div>
                <div className="py-2">
                  <label>Org profiles found:</label>
                  {orgProfiles.map((org, idx) => (
                    <p key={`org-${idx}`}>
                      {JSON.stringify(
                        pickProps(
                          ["name", "orgProfileId", "enpoweredApps"],
                          org
                        )
                      )}
                      {idx === 0 ? <b>{"<== Will be used"}</b> : ""}
                    </p>
                  ))}
                </div>
                <button
                  type="button"
                  className="yellow"
                  onClick={() => {
                    sendInvitations(
                      values.usersToInvite,
                      orgProfiles[0]?.orgProfileId
                    )
                      .then(() => {
                        console.log("invitations sent: ", values.usersToInvite);
                        setMessage({
                          text: "Invitations sent secessfully!",
                          type: "info"
                        });
                        setIsMessageSnackBarOpen(true);
                        setFieldValue("usersToInvite", []);
                        setFieldValue("partnerCompanyId", "");
                      })
                      .catch(e => {
                        console.error("Error on sending invites: ", e);
                        setIsMessageSnackBarOpen(true);
                        setMessage({
                          text: JSON.stringify(e?.data?.cause),
                          type: "error"
                        });
                      });
                  }}
                >
                  Send Invites
                </button>
              </div>
            </div>
          );
        }}
      </Formik>
      <div className="mx-2 mt-2">{statusMessage}</div>
    </div>
  );
};
