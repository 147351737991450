import React, { useEffect, useState } from "react";
import "./styles.scss";
import InitialPage from "./InitialPage";
import SnackBar from "common/SnackBar";
import { useQuery } from "react-query";
import { getCreditAppById } from "services/CreditApps";
import { useParams } from "react-router-dom";
import { getPartnerCompany } from "../../services/PartnerCompany";
import { CustomerFacingTopBar } from "common/CustomerFacingTopBar";
import { getMixpanel } from "../../services/mixpanel";
import classNames from "classnames";
import { updateCreditApp, createSummaryFile } from "services/CreditApps";
import { TAB_FINANCING_APPLICATION } from "./CreditAppFeature";
import LoadingModal from "common/LoadingModal";
import CustomerCreditAppForm from "./CustomerCreditAppForm";
import PageNotFound from "../../common/PageNotFound";
import { getFinancingScenarioById } from "../../services";

export const TAB_CUSTOMER_VIEW = "customerView";

function CreditApp() {
  const { creditAppId } = useParams();
  const {
    data,
    refetch,
    isLoading: isLoadingCreditApp,
    isError
  } = useQuery(
    `credit-app-${creditAppId}`,
    async () =>
      creditAppId &&
      getCreditAppById(creditAppId).then(
        creditApp =>
          creditApp?.partnerCompanyId &&
          getPartnerCompany(creditApp.partnerCompanyId).then(
            partnerCompany => ({
              creditApp,
              partnerCompany: {
                ...partnerCompany,
                ...(partnerCompany.partnerLogoFilename
                  ? {
                      partnerLogoUrl: `https://${process.env.REACT_APP_PARTNER_COMPANIES_IMAGES_BUCKET_CLOUDFRONT_URL}/${partnerCompany.partnerLogoFilename}`
                    }
                  : {})
              }
            })
          )
      )
  );
  const { creditApp = {}, partnerCompany = {} } = data ?? {};
  const {
    data: financingScenario,
    isLoading: isLoadingFinancingScenario,
    isError: isErrorLoadingFinancingScenario
  } = useQuery(
    `financing-scenario-${creditApp?.financingScenarioId}`,
    async () => {
      return (
        creditApp?.financingScenarioId &&
        getFinancingScenarioById(creditApp.financingScenarioId)
      );
    }
  );
  const localStorageUserEmail = localStorage.getItem("currentUserEmail");
  const [userEmail, setUserEmail] = useState(localStorageUserEmail);
  const [showCreditAppForm, setShowCreditAppForm] = useState(
    !!localStorageUserEmail
  );
  const [errors, setErrors] = useState([]);
  const [isMessageSnackBarOpen, setIsMessageSnackBarOpen] = useState(true);

  const mixpanelTrack = (msg, additionalProps) =>
    getMixpanel().track(msg, {
      "Application ID": creditApp.creditAppId,
      userEmail: localStorage.getItem("currentUserEmail"),
      ...additionalProps
    });

  const onInitialPageCompleted = () => {
    localStorage.setItem("currentUserEmail", userEmail);
    getMixpanel().track("Credit App Contributor Email Provided", {
      "Application ID": creditAppId,
      userEmail
    });
    setShowCreditAppForm(true);
  };

  useEffect(() => {
    localStorageUserEmail
      ? getMixpanel().track("Credit App Viewed", {
          "Application ID": creditAppId,
          localStorageUserEmail
        })
      : getMixpanel().track("Credit Application Entered", {
          "Application ID": creditAppId
        });
  }, []);

  const onSubmissionConfirmed = values => {
    const userEmail = localStorage.getItem("currentUserEmail");
    return values.creditAppId && userEmail
      ? updateCreditApp(userEmail, {
          ...values,
          isSubmitted: true,
          isCustomerView: true,
          formSubmitted: TAB_CUSTOMER_VIEW
        }).then(() => createSummaryFile(values.creditAppId))
      : Promise.reject("Credit app id or user email not found");
  };

  return (
    <div
      className={classNames("creditAppPage", {
        showForm: showCreditAppForm
      })}
    >
      <SnackBar
        messages={[...Object.entries(errors).map(([, message]) => message)]}
        setIsOpen={setIsMessageSnackBarOpen}
        isOpen={isMessageSnackBarOpen}
        type={"error"}
        duration={5000}
      />
      {!isLoadingCreditApp && !creditApp?.creditAppId ? (
        <>
          <CustomerFacingTopBar partnerCompany={partnerCompany} />
          <PageNotFound />
        </>
      ) : showCreditAppForm ? (
        <>
          <CustomerFacingTopBar partnerCompany={partnerCompany} />
          <div className="navAndContent">
            {(isLoadingCreditApp ||
              isLoadingFinancingScenario ||
              isError ||
              isErrorLoadingFinancingScenario) && (
              <LoadingModal
                errorOnLoad={isError || isErrorLoadingFinancingScenario}
              />
            )}
            <CustomerCreditAppForm
              creditAppData={creditApp}
              financingScenario={financingScenario}
              mixpanelTrack={mixpanelTrack}
              onSubmissionConfirmed={onSubmissionConfirmed}
              currentTab={TAB_FINANCING_APPLICATION}
              refetchCreditApp={refetch}
              partnerCompanyName={partnerCompany.partnerCompanyName}
              userEmail={localStorageUserEmail}
            />
          </div>
        </>
      ) : (
        <InitialPage
          partnerCompanyName={partnerCompany.partnerCompanyName}
          partnerLogoUrl={partnerCompany.partnerLogoUrl}
          onCompleteClicked={onInitialPageCompleted}
          errors={errors}
          setErrors={setErrors}
          userEmail={userEmail}
          setUserEmail={setUserEmail}
        />
      )}
    </div>
  );
}

export default CreditApp;
