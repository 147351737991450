import { handleHttpError } from "../utils/handleHttpError";
import { pickProps } from "../utils/objectFunctions";

export const searchCompanyByBusinessName = (businessName, countryCode) =>
  (process.env.REACT_APP_LOCAL_ENV === "true"
    ? Promise.resolve({
        results: {
          companies: [
            {
              company: {
                name: "FDDF INC.",
                company_number: "5780592",
                jurisdiction_code: "us_ny",
                incorporation_date: "2020-07-03",
                dissolution_date: null,
                company_type: "DOMESTIC BUSINESS CORPORATION",
                registry_url: "https://apps.dos.ny.gov/publicInquiry/",
                branch: null,
                branch_status: null,
                inactive: false,
                current_status: "Active",
                created_at: "2020-09-11T15:12:50+00:00",
                updated_at: "2024-05-15T23:12:33+00:00",
                retrieved_at: "2024-05-13T11:37:21+00:00",
                opencorporates_url:
                  "https://opencorporates.com/companies/us_ny/5780592",
                previous_names: [],
                alternative_names: [],
                source: {
                  publisher: "New York Department of State",
                  url: "https://apps.dos.ny.gov/publicInquiry/",
                  retrieved_at: "2024-05-13T11:37:21+00:00"
                },
                registered_address: {
                  street_address: "2661 WEST 2 ST 4H",
                  locality: "BROOKLYN",
                  region: "NY",
                  postal_code: "11223",
                  country: "United States"
                },
                registered_address_in_full:
                  "2661 WEST 2 ST 4H, BROOKLYN, NY, 11223",
                industry_codes: [],
                restricted_for_marketing: null,
                native_company_number: null,
                countryCode: "US",
                state: "NY"
              }
            }
          ]
        }
      })
    : fetch(
        "https://api.opencorporates.com/companies/search?" +
          new URLSearchParams({
            api_token: process.env.REACT_APP_OPEN_CORPORATES_API_KEY,
            q: `${businessName}*`,
            jurisdiction_code: (countryCode ?? "US").toLowerCase() + "_*"
          }),
        { method: "GET" }
      )
        .then(handleHttpError)
        .then(res => res.json())
  ).then(res =>
    res?.results?.companies
      ?.map(company => {
        const [countryCode, state] =
          company?.company?.jurisdiction_code?.toUpperCase()?.split("_") ?? [];
        return {
          ...getRelevantProperties(company?.company),
          name: company?.company?.name,
          countryCode,
          state,
          city: company?.company?.registered_address?.locality ?? "",
          postalCode: company?.company?.registered_address?.postal_code ?? "",
          streetAddress:
            company?.company?.registered_address?.street_address ?? ""
        };
      })
      .filter(c => !!c.name)
  );

const getRelevantProperties = company =>
  pickProps(
    [
      "name",
      "branch",
      "branch_status",
      "company_number",
      "jurisdiction_code",
      "incorporation_date",
      "dissolution_date",
      "company_type",
      "inactive",
      "current_status",
      "created_at",
      "updated_at",
      "retrieved_at",
      "opencorporates_url",
      "previous_names",
      "alternative_names",
      "source",
      "registered_address",
      "dissolution_date",
      "registered_address_in_full",
      "industry_codes",
      "nonprofit",
      "business_number",
      "current_alternative_legal_name",
      "has_been_liquidated",
      "has_insolvency_history"
    ],
    company
  );
