import { Cookies } from "react-cookie";
import {
  COOKIE_FA_USER,
  COOKIE_USER_CREDENTIALS
} from "../../auth/contexts/user";
import { removeCookie } from "../../utils/cookies";

export const logout = () => {
  removeCookie(COOKIE_USER_CREDENTIALS);
  removeCookie(COOKIE_FA_USER);
  redirectToLogin();
};

export const redirectToLogin = () => {
  window.location.href = `${process.env.REACT_APP_IAM_UI_URL}/logout`;
};

export const getIdToken = async () =>
  getUser().then(cookieUser => cookieUser?.idToken);

export const getUser = async () => {
  return new Cookies().get(COOKIE_USER_CREDENTIALS);
};
