import React from "react";
import "./styles.scss";
import Modal from "../../../common/Modal";
import IconTextButton from "../../../common/IconTextButton";

function SubmitConfirmationModal({
  isOpen,
  setIsOpen,
  onConfirmed,
  mixpanelTrack
}) {
  return (
    <Modal
      className={"submitConfirmationModal bg-background-modal"}
      isOpen={isOpen}
      centered={false}
    >
      <div className="flex justify-between">
        <div></div>
        <h2 className="text-center">Submit Financing Application </h2>
        <div className="relative w-0 h-0">
          <span
            className="x"
            onClick={() => {
              mixpanelTrack("Credit App Cancel Submission");
              setIsOpen(false);
            }}
          >
            ×
          </span>
        </div>
      </div>
      <div className="px-6 mt-2">
        <p className="font-semibold">
          Before you finish, there is some legal information that we need to ask
          you to read:
        </p>
        <p>
          We may share this information with potential funding partners to
          pursue funding for the project, and use it for internal purposes, such
          as credit inquiries and other verifications respecting the customer
          identified in this application, their business and the project.
        </p>
        <p>
          Please ensure information is accurate and complete, and notify us of
          any changes, otherwise it may affect the potential funding or credit.
        </p>
        <p>
          We do not guarantee that we will find an appropriate funding partner,
          or that any funding partner will agree to extend credit or funding.
          Additionally, any credit or funding will be subject to the funding{" "}
          {`partner's`} terms and conditions.
        </p>
        <p>
          Any credit checks performed will be soft credit checks, which will not
          affect the {`customer's`} credit. If a hard credit check is required,
          we will notify the customer.
        </p>
        <p>
          If you are a third party submitting this application on behalf of the
          customer identified in the application, it is your responsibility to
          obtain the {`customer's`} consent to share this information with us
          for these purposes, as well as their agreement to the terms herein.
          You will indemnify us against any claims relating to your breach of
          these obligations.
        </p>
      </div>
      <p className="font-light">
        By clicking, <span className="font-normal">submit application</span>, I
        warrant that I have the authority to provide the information herein,
        consent to the usage of such information as specified herein, and
        otherwise agree to the terms of this document.
      </p>
      <IconTextButton
        filled
        primary
        className="submitBtn borderYellow1 py-4 font-light"
        label="Submit application"
        onClick={() => {
          typeof onConfirmed === "function" && onConfirmed();
          setIsOpen(false);
        }}
      />
    </Modal>
  );
}

export default SubmitConfirmationModal;
