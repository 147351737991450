import { AutocompleteInput, Label, TextInput } from "@enpowered/ui";
import React from "react";
import classNames from "classnames";
import "./styles.scss";
import RadioGroup from "common/RadioGroup";

import {
  calculateDepreciation,
  getDefaultTaxRateByRegion,
  isDepreciationIncentive
} from "utils/incentives";
import TitleBar from "../../TitleBar";
const getDefaultSolarTermLength = organizationType =>
  [
    "PrivateCompany",
    "PrivateNonProfit",
    "ReligiousOrganization",
    "Farm",
    "Other"
  ].includes(organizationType)
    ? 5
    : ["PublicCompany", "PublicNonProfit"].includes(organizationType)
      ? 7
      : 10;

const getDefaultInterestRateByOrganizationType = organizationType =>
  [
    "PrivateCompany",
    "PrivateNonProfit",
    "ReligiousOrganization",
    "Farm",
    "Other"
  ].includes(organizationType)
    ? 11
    : ["PublicCompany", "PublicNonProfit"].includes(organizationType)
      ? 10
      : 9;

export const getOrganizationTypeOptions = isTaxExempt => [
  { label: "Choose Type", value: "" },
  ...(isTaxExempt
    ? [
        {
          label: "Private Non-Profit",
          value: "PrivateNonProfit"
        },
        {
          label: "Public Non-Profit",
          value: "PublicNonProfit"
        }
      ]
    : [
        {
          label: "Private Company",
          value: "PrivateCompany"
        },
        {
          label: "Public Company",
          value: "PublicCompany"
        }
      ]),
  { label: "Farm", value: "Farm" },
  { label: "Government entity", value: "GovernmentEntity" },
  { label: "Hospital", value: "Hospital" },
  { label: "Religious organization", value: "ReligiousOrganization" },
  { label: "School", value: "School" },
  { label: "University", value: "University" },
  { label: "Other", value: "Other" }
];

const CustomerInfoStep = ({
  startClicked,
  formProps,
  hasError,
  validationOnChanged,
  infoAssistOn,
  setInfoAssistOn,
  proposalInputsForCalculator
}) => {
  const { handleBlur, values, setFieldValue } = formProps;
  const organizationTypeOptions = getOrganizationTypeOptions(
    values.isTaxExempt
  );

  const updateDepreciationRates = (incentives, countryCode, state) => {
    const updatedIncentives = incentives ?? [];
    const depreciationIncentive = updatedIncentives.find(i =>
      isDepreciationIncentive(i.name)
    );
    if (depreciationIncentive) {
      const { federalTaxRate, stateTaxRate } = getDefaultTaxRateByRegion(
        countryCode,
        state
      );
      depreciationIncentive.federalTaxRate = federalTaxRate;
      depreciationIncentive.stateTaxRate = stateTaxRate;
      return calculateDepreciation(
        proposalInputsForCalculator,
        ((+federalTaxRate ?? 0) + (+stateTaxRate ?? 0)) / 100
      ).then(({ amount, otherYears }) => {
        depreciationIncentive.amount = amount;
        depreciationIncentive.otherYears = otherYears;
        setFieldValue("incentives", updatedIncentives);
      });
    }
  };

  return (
    <div className="customerInfoStep">
      <TitleBar
        proposalInputs={values}
        infoAssistOn={infoAssistOn}
        setInfoAssistOn={setInfoAssistOn}
      />
      <div className="mt-6 mb-4 ml-4">
        <div className="mb-4">
          <Label>Customer company name</Label>
          <TextInput
            name="customerCompanyName"
            id="customerCompanyName"
            size="lg"
            placeholder="Type company name"
            value={values.customerCompanyName}
            onChange={e => {
              setFieldValue("customerCompanyName", e.target.value);
              validationOnChanged("customerCompanyName", e.target.value);
            }}
            valid={!hasError("customerCompanyName")}
            disabled={values.isArchived}
            onBlur={handleBlur}
          />
        </div>
      </div>
      <div className="ml-4">
        <div className="radioField isTaxExemptField">
          <Label>Is the customer tax-exempt?</Label>
          <RadioGroup
            className="mt-2 pr-2"
            name="isTaxExempt"
            id="isTaxExempt"
            values={[
              { value: "true", label: "Yes" },
              { value: "false", label: "No" }
            ]}
            currentValue={values?.isTaxExempt ? "true" : "false"}
            direction="horizontal"
            disabled={values.isArchived}
            size="small"
            onChange={async selected => {
              setFieldValue("isTaxExempt", selected.target.value == "true");
              if (selected.target.value == "true") {
                if (
                  values.incentives?.some(i => isDepreciationIncentive(i.name))
                ) {
                  const updatedIncentives = values.incentives;
                  updatedIncentives.splice(
                    values.incentives.findIndex(i =>
                      isDepreciationIncentive(i.name)
                    ),
                    1
                  );
                  setFieldValue("incentives", updatedIncentives);
                }
              } else if (
                (values.incentives ?? []).every(
                  i => !isDepreciationIncentive(i.name)
                )
              ) {
                await updateDepreciationRates(
                  (values.incentives ?? []).concat({
                    name: values.countryCode === "CA" ? "CCA" : "MACRS"
                  }),
                  values.countryCode,
                  values.state
                );
              }
              if (
                values.organizationType.indexOf("Company") >= 0 ||
                values.organizationType.indexOf("NonProfit") >= 0
              )
                setFieldValue("organizationType", "");
            }}
          />
        </div>
        <div className="inputField">
          <Label>Organization Type</Label>
          <AutocompleteInput
            className={classNames({
              "selectField p-1 pr-8": true,
              error: hasError("organizationType")
            })}
            name="organizationType"
            id="organizationType"
            autocompleteData={organizationTypeOptions}
            getOptionLabel={o => o.label}
            value={
              organizationTypeOptions.find(
                t => t.value === values?.organizationType
              ) ?? organizationTypeOptions.find(t => t.value === "")
            }
            maxResults={10}
            onSelected={selected => {
              setFieldValue("organizationType", selected.value);
              values.projectType === "Solar" &&
                setFieldValue(
                  "termLength",
                  getDefaultSolarTermLength(selected.value)
                );
              validationOnChanged("organizationType", selected.value);
              setFieldValue(
                "interestRate",
                getDefaultInterestRateByOrganizationType(selected.value)
              );
            }}
            valid={!hasError("organizationType")}
            disabled={values.isArchived}
          />
        </div>
      </div>
      <div className="ml-4 w-50">
        <button type="button" className="textButton" onClick={startClicked}>
          {"Next"}
        </button>
      </div>
    </div>
  );
};

export default CustomerInfoStep;
