import React from "react";
import { getOrganizationTypeOptions } from "../steps/CustomerInfo";

const getMaxRecommendedTermLength = (projectType, organizationType) =>
  projectType === "Lighting"
    ? 5
    : projectType !== "Solar"
      ? 7
      : [
            "PrivateCompany",
            "PrivateNonProfit",
            "ReligiousOrganization",
            "Farm",
            "Other"
          ].includes(organizationType)
        ? 7
        : ["PublicCompany", "PublicNonProfit"].includes(organizationType)
          ? 10
          : 15;

const recommendedValuesMessages = proposal => {
  const organizationTypeLabel = getOrganizationTypeOptions(
    proposal.isTaxExempt
  ).find(opt => opt.value === proposal.organizationType)?.label;
  return {
    projectCost: [
      `Our funder network can finance projects of $25,000 and more. If your client is open to making a down payment 
    for this project you can deduct the total down payment amount from the project cost.`
    ],
    termLength:
      proposal.projectType === "Lighting"
        ? [
            <span key="termLengthProMessage">
              {`The duration of the loan depends on the funder's confidence the customer will be in business 
      throughout the term. `}
              <b>LED lighting</b>
              {` loan repayment terms typically span `}
              <b>1 to 5 years</b>.
            </span>
          ]
        : proposal.organizationType === "Other"
          ? [
              <span key="termLengthProMessage">
                {`The duration of the loan depends on the funder's confidence the customer will be in business 
        throughout the term. Generally`}
                <b>
                  {proposal.projectType === "Other"
                    ? ""
                    : proposal.projectType === "HVAC"
                      ? ` ${proposal.projectType}`
                      : ` ${proposal.projectType?.toLowerCase()}`}
                </b>
                {` loan repayment terms typically span from `}
                <b>1 to 7 years</b>.
              </span>
            ]
          : [
              <span key="termLengthProMessage">
                {`The duration of the loan depends on the funder's confidence the
              customer will be in business throughout the term. For ${
                /^[AEIOUaeiou].*/.test(organizationTypeLabel) ? "an" : "a"
              } `}
                <b>{organizationTypeLabel?.toLowerCase()}</b>,
                <b>
                  {proposal.projectType === "Other"
                    ? ""
                    : proposal.projectType === "HVAC"
                      ? ` ${proposal.projectType}`
                      : ` ${proposal.projectType?.toLowerCase()}`}
                </b>
                {" loan repayment terms typically span from "}
                <b>
                  {"1 to "}
                  {getMaxRecommendedTermLength(
                    proposal.projectType,
                    proposal.organizationType
                  )}
                  {" years"}
                </b>
                .
              </span>
            ],
    interestRate: [
      `The interest rate is determined by the organization type you selected in the 
      project details view. The actual interest rate, once we secure funding, may vary 
      depending on customer credit — either higher or lower.`
    ],
    percentageOfSavings: [
      `Matching payments to savings involves setting the payment amount as a percentage 
    of your savings. If you select the 'match payments to savings' feature please 
    ensure the term calculated is between 1 to 10 years. You can increase the allocated 
    savings percentage to reduce the term if needed.`
    ],
    otherCostSavings: [
      `Input the total monetary value of SRECs (Solar Renewable Energy Credits), RECs 
  (Renewable Energy Certificates), and any other ancillary revenue streams that will offset the 
  project costs.`
    ],
    currentUtilityCost: [
      `For a compelling financing proposal please provide the utility costs, 
  we use this information to illustrate the utility costs reduction in our tables and graphs.`
    ],
    OEMCost: [
      "The O&M costs is an annual expense and will be shown as negatively impacting the net savings."
    ],
    estimatedConstructionStartDate: [
      `Through our network of funders, you can access funding for both construction and projects. 
    The start date of the construction, along with the construction period, helps determine the 
    appropriate commercial depreciation schedule to use when incorporating it into the savings.`
    ],
    constructionPeriodLength: [
      `The construction period determines the timeframe during which interest 
  will accrue before the customer begins repaying the loan. It's important to note that both the length 
  of the construction period and the agreed-upon progress payments impact the cash flow positivity of a project.`
    ],
    progressPayments: [
      `If you've chosen a construction period, enter the payment amount and due month for 
  each progress payment installment.`,
      `Projects typically have progress payments with 50% upon commencement and 50% after completion. 
  If your project requires additional progress payments or long construction periods it will 
  negatively impact the cash flow positivity of the project.`
    ],
    systemDegradationRate: [
      `The system degradation rate calculates the annual reduction in a solar 
  system's electricity production capacity. The degradation rate negatively impacts the savings 
  potential of an installation. `
    ],

    utilityCostEscalator: [
      `Our utility cost escalator is based on the latest data from regional energy 
  providers, reflecting the estimated yearly increase in utility costs. If you have used a different 
  number in your calculations, please make sure to adjust this figure accordingly. We use this number 
  in our payment calculations to boost the savings.`
    ],
    incentives:
      proposal.projectType === "Lighting"
        ? [
            `Enter the aggregated value of all financial incentives, 
          such as utility rebates, that will be paid down as a lump sum payment 
          in month 6 of the LED lighting project.`,
            `Note that some customers may be unable 
          to fully utilize the financial incentives depending on their current tax liability, 
          which will affect the cash flow nature of the project.`
          ]
        : proposal.projectType === "Solar"
          ? [
              `Enter the aggregated value of input tax credits (ITC), ${
                proposal.countryCode === "CA"
                  ? "capital cost allowance (CCA)"
                  : "REAP grants"
              }, and any other financial incentives applicable to your solar project.`,
              `Note that some customers may be unable to fully utilize the tax credits or 
            depreciation depending on their current tax liability, which will affect the 
            cash flow nature of the project.`
            ]
          : [
              `Enter the aggregated value of input tax credits (ITC), ${
                proposal.countryCode === "CA"
                  ? "capital cost allowance (CCA)"
                  : "REAP grants"
              } 
            and any other financial incentives applicable to your project.`,
              `Note that some customers may be unable to fully utilize the tax credits 
            or depreciation depending on their current tax liability, which will affect 
            the cash flow nature of the project.`
            ]
  };
};
const isInRecommendedValues = (field, value) => {
  return {
    //example projectCost: value => value >= 25000
  }[field]?.(value);
};

export const getInfoMessagesForProperty = (proposal, fieldName) =>
  isInRecommendedValues(fieldName, proposal[fieldName])
    ? []
    : recommendedValuesMessages(proposal)[fieldName];
