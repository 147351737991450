import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { getRepresentativeByEmail } from "services/Representatives";
import {
  getMe,
  sendSignedInUserToMixpanel
} from "../../../services/auth/login";
import {
  CognitoIdentityProviderClient,
  InitiateAuthCommand
} from "@aws-sdk/client-cognito-identity-provider"; // ES Modules import
import { logout } from "../../../services/auth/token";
import { getCookie, removeCookie, setCookie } from "../../../utils/cookies";
import { getEnvironmentDomainUrlSuffix } from "../../../utils/fetch";

export const COOKIE_USER_CREDENTIALS = `userCredentials${getEnvironmentDomainUrlSuffix()}`;
export const COOKIE_FA_USER = `financingAcceleratorUser${getEnvironmentDomainUrlSuffix()}`;

const cognitoClient = new CognitoIdentityProviderClient({
  region: "us-east-1"
});

export const isAuthenticatedPage = () =>
  window.location.pathname.indexOf("/creditApp/") !== 0;

export const UserContext = React.createContext({
  user: {
    email: null,
    emailVerified: false,
    id: null
  },
  setUser: () => {},
  isLoggedIn: false,
  clear: () => {},
  refresh: async () => {},
  internalUserRefresh: async () => {},
  initUser: () => Promise.resolve()
});

export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(getCookie(COOKIE_USER_CREDENTIALS) || null);
  const isLoggedIn = !!user;

  const clear = () => {
    removeCookie(COOKIE_USER_CREDENTIALS);
    setUser(null);
  };

  const refresh = () => {
    if (!user?.refreshToken)
      if (!getCookie(COOKIE_USER_CREDENTIALS).refreshToken)
        return Promise.reject("Error refreshing session: No id/token found");
      else return Promise.resolve();
    const input = {
      AuthFlow: "REFRESH_TOKEN",
      AuthParameters: {
        REFRESH_TOKEN: user.refreshToken
      },
      ClientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID
    };

    const command = new InitiateAuthCommand(input);
    return cognitoClient
      .send(command)
      .then(res =>
        initUser(
          {
            ...user,
            idToken: res?.AuthenticationResult?.IdToken
          },
          getRepresentativeByEmail
        )
      )
      .catch(async err => {
        console.error("Error refreshing user", err);
        return Promise.reject(err);
      });
  };
  const internalUserSingleSignOnInitialize = async token => {
    // const token = await getIdToken();
    console.log({ token });
    const res = await getMe(token);
    console.log({ internalUserRefreshGetMe: res });
    // localStorage.setItem(
    //   ENPOWERED_USER,
    //   JSON.stringify({
    //     ...user,
    //     ...res?.data?.attributes,
    //     id: res?.data?.id
    //   })
    // );
    await initUser(
      {
        ...user,
        ...res?.data?.attributes,
        id: res?.data?.id
      },
      getRepresentativeByEmail
    );
  };
  const initUser = async (
    { id, email, emailVerified, idToken, ...attributes },
    getRepresentativeByEmail = () => Promise.resolve({})
  ) => {
    const user = {
      ...attributes,
      id,
      email,
      emailVerified,
      idToken
    };
    if (user.idToken) {
      setCookie(COOKIE_USER_CREDENTIALS, user);
    }

    const proposalServiceUser = (await getRepresentativeByEmail(email)) ?? {};
    if (!proposalServiceUser?.partnerCompanyId)
      return Promise.reject(
        "User not enrolled in any company on the Financing Accelerator"
      );

    user.partnerCompanyId = proposalServiceUser.partnerCompanyId;
    user.partnerRepName = proposalServiceUser.partnerRepName;
    user.partnerRepId = proposalServiceUser.partnerRepId;

    if (!getCookie(COOKIE_FA_USER)?.partnerCompanyId)
      sendSignedInUserToMixpanel(user);

    setCookie(COOKIE_FA_USER, proposalServiceUser);

    setUser(user);
    return user;
  };

  const value = {
    user,
    setUser,
    isLoggedIn,
    clear,
    refresh,
    internalUserSingleSignOnInitialize,
    initUser
  };

  useEffect(() => {
    const intervalFun = intervalId =>
      refresh().catch(err => {
        console.error("Error refreshing user", err);
        clearInterval(intervalId);
        setUser(null);
        logout();
      });
    let refreshIntervalId = null;
    if (isAuthenticatedPage()) {
      refreshIntervalId = setInterval(
        () => intervalFun(refreshIntervalId),
        5 * 60 * 1000 // refresh every 5 minutes
      );
      intervalFun(refreshIntervalId);
    }
    return () => isAuthenticatedPage() && clearInterval(refreshIntervalId);
  }, []);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

UserContextProvider.propTypes = {
  children: PropTypes.any
};
